import React from 'react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import styled from 'styled-components'

const ContainerSocial = styled.div`
  display: flex;
  width: 150px;
  justify-content: space-between;
  margin-top: 0px;
  float: right;
  margin-right: 20px;
`

interface ISocialProps {
  type: string
}

const Social = styled.a`
  color: ${(props: ISocialProps) => getSocialColor(props.type)};
`

const SOCIAL_ICON_SIZE = 30

const getSocialColor = (type: string) => {
  switch (type) {
    case 'facebook':
      return '#3b5998'
    case 'twitter':
      return '#1DA1F2'
    case 'linkedIn':
      return '#0077B5'
    default:
      return 'black'
  }
}

interface IProps {
  profile: {
    name: string
    github: string
    linkedIn: string
    twitter: string
  }
}
const SocialLinks = ({ profile }: IProps) => {
  return (
    <ContainerSocial>
      <Social
        type="black"
        target="_blank"
        rel="noopener noreferrer"
        href={profile.github}
        aria-label={`${profile.name}'s Github`}
      >
        <FaGithub size={SOCIAL_ICON_SIZE} />
      </Social>
      <Social
        type="linkedIn"
        target="_blank"
        rel="noopener noreferrer"
        href={profile.linkedIn}
        aria-label={`${profile.name}'s LinkedIn`}
      >
        <FaLinkedin size={SOCIAL_ICON_SIZE} />
      </Social>
      <Social
        type="twitter"
        target="_blank"
        rel="noopener noreferrer"
        href={profile.twitter}
        aria-label={`${profile.name}'s Twitter`}
      >
        <FaTwitter size={SOCIAL_ICON_SIZE} />
      </Social>
    </ContainerSocial>
  )
}

export default SocialLinks
