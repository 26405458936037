import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import NavBar from '../NavBar'
import SocialLinks from '../SocialLinks'

const NAV_BAR_HEIGTH = 50
const FOOTER_HEIGHT = 50

const Container = styled.div``

const ContainerNavBar = styled.div`
  height: ${NAV_BAR_HEIGTH}px;
  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
`

const ContainerContent = styled.div<IContainerContentProps>`
  width: ${props => (props.isIndexRoute ? '100%' : '70%')};
  margin: auto;
  margin-top: ${NAV_BAR_HEIGTH + 20}px;
  margin-bottom: ${FOOTER_HEIGHT + 20}px;
`

const ContainerFooter = styled.div`
  background: white;
  bottom: 0px;
  height: ${FOOTER_HEIGHT}px;
  left: 0px;
  position: fixed;
  width: 100%;
  overflow: hidden;
`

interface IContainerContentProps {
  isIndexRoute: boolean
}

const Layout = ({
  children,
  location,
}: {
  children: any
  location: Location
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          profile {
            name
            about
            github
            linkedIn
            twitter
          }
        }
      }
    }
  `)
  const { siteMetadata } = data.site
  const { title, profile } = siteMetadata
  const isIndexRoute = location.pathname === '/'

  return (
    <Container>
      <Helmet>
        <html lang="en" />
        <meta name="description" content="Harsh's Portfolio" />
        <title>{title}</title>
      </Helmet>
      {/*<ContainerNavBar>*/}
      {/*  <NavBar location={location} name={profile.name} />*/}
      {/*</ContainerNavBar>*/}
      <ContainerContent isIndexRoute={isIndexRoute}>
        {children}
      </ContainerContent>
      <ContainerFooter>
        <SocialLinks profile={profile} />
      </ContainerFooter>
    </Container>
  )
}

export default Layout
